import React from "react";

export const data_photo = {
  what_we_do: {
    title: "Atrakcyjna forma i jasny przekaz",
    text: [
      <>
        <b>Foto</b> - profesjonalne sesje zdjęciowe, które wykonujemy, pokażą
        Twoje produkty w jak najlepszej odsłonie. Zapewniamy wysoką jakość
        zdjęć, na których uwiecznimy i wyeksponujemy atuty Twojej marki.
      </>,
      <>
        <b>Video</b> - materiały wideo, które tworzymy, inspirują i zapadają w
        pamięć. Każdy element pokazujemy w odpowiednim kontekście i z jak
        najlepszej strony. Z chęcią pokażemy też Twoją wyjątkowość i
        niepowtarzalność.
      </>,
      <>
        <b>Animacje </b> – innowacyjna forma reklamy, która podkreśli wszystkie
        zalety i odda charakter Twojej marki. Prosty i czytelny przekaz animacji
        stworzy przyjazny i profesjonalny wizerunek.
      </>,
      <>
        Chcesz jeszcze inaczej zaprezentować swoje usługi lub produkty? Jesteśmy
        otwarci na propozycje! Obiecujemy, że dołożymy wszelkich starań, aby ta
        misja okazała się sukcesem!
      </>,
    ],
  },
};
